<template>
  <el-row style="margin-bottom:50px">
    <div class="main-width-80">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">Home</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/express/track' }"
        >Track items
        </el-breadcrumb-item
        >
      </el-breadcrumb>
      <el-container style="padding-top: 20px">
        <el-main :style="`padding: 0;margin-right:20px;`">
          <el-tabs
              v-model="activeName"
              @tab-click="handleClick"
              type="border-card"
          >
            <el-tab-pane :name="i.name" v-for="(i, j) in tabList" :key="j">
              <div slot="label">
                <el-popover width="450" trigger="hover" placement="bottom-end">
                  <div class="custom-popover">
                    <h6 :class="i.textColor">{{ i.title }}</h6>
                    <p>{{ i.summary }}</p>
                    <div class="content" v-html="i.content"></div>
                  </div>
                  <p slot="reference" class="custom-tab">
                    <i :class="`icon-i ${i.icon}`" v-if="j != 0"></i>
                    <i :class="i.textColor">{{ i.title }}</i
                    ><i :class="i.textColor">({{ i.count }})</i>
                  </p>
                </el-popover>
              </div>
              <div :style="`min-height:400px`">
                <div v-if="i.data.length > 0">
                  <div v-for="(item, index) in i.data" :key="index">
                    <div v-if="j == tabIndex">
                      <el-row class="tabs-item-title">
                        <el-col :span="6" class="col-1">
                          <span :class="['success-icon',item.bg]">
                              <i :class="item.icon"></i>
                          </span>
                          <div class="wldh">
                            <p class="dh">
                              <span>{{ item.trackNumber }}</span>
                              <!-- <i class="fz"></i> -->
                            </p>
                            <p class="day">{{ item.statusDesc }}</p>
                          </div>
                        </el-col>
                        <el-col :span="6">
                          <p class="address">
                            <span>{{ item.fromCountry }}</span>
                            <i></i>
                            <span>{{ item.toCountry }}</span>
                          </p>
                        </el-col>
                        <el-col :span="11">
                          <p class="details" v-if="item.latestTrackLog">
                            <span>{{ item.latestTrackLog.time }}</span>
                            <span>{{ item.latestTrackLog.log }}</span>
                          </p>
                        </el-col>
                        <el-col :span="1">
                          <span class="i-block" @click="toggle(index,j,item)">
                            <i
                                :class="
                                item.check
                                  ? 'el-icon-arrow-down'
                                  : 'el-icon-arrow-up'
                              "
                            ></i>
                            <i
                                :class="
                                item.check
                                  ? 'el-icon-arrow-up'
                                  : 'el-icon-arrow-down'
                              "
                            ></i>
                          </span>
                        </el-col>
                      </el-row>
                      <el-divider></el-divider>
                      <div v-if="item.check">
                        <p class="el-timeline-p">Destination</p>
                        <el-timeline v-if="item.trackLog1">
                          <el-timeline-item
                              v-for="(activity, index) in item.trackLog1"
                              :key="index"
                              :color="index == 0 ? '#0bbd87' : ''"
                              size="large"
                              :timestamp="activity.time"
                          >
                            {{ activity.log }}
                          </el-timeline-item>
                        </el-timeline>
                        <p class="el-timeline-p">Origin</p>
                        <el-timeline v-if="item.trackLog0">
                          <el-timeline-item
                              v-for="(activity, index) in item.trackLog0"
                              :key="index"
                              :color="index == 0 ? '#0bbd87' : ''"
                              :timestamp="activity.time"
                          >
                            {{ activity.log }}
                          </el-timeline-item>
                        </el-timeline>
                      </div>
                    </div>
                  </div>
                </div>

                <el-empty v-else description="No tracking number."></el-empty>
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-main>
        <el-aside width="250px">
          <ul class="aside-menu-list">
            <li>
              <el-input
                  type="textarea"
                  clearable=""
                  :rows="12"
                  placeholder="Enter one tracking number for each line. A maximum of 20 entries can be submitted"
                  v-model="textarea"

              >
                <!-- @input="setValueLength" -->
              </el-input>
              <div class="btn">
                <el-button
                    icon="el-icon-search"
                    type="primary"
                    @click="getTrackList"
                >Track
                </el-button
                >
              </div>
            </li>
          </ul>
        </el-aside>
      </el-container>
    </div>
  </el-row>
</template>

<script>
import {websitePage, expressTrack} from "../api/index.js";
import data from "./data.json";

export default {
  data() {
    return {
      pageId: this.$route.query.pageId,
      pageContent: {},
      activeName: "-1",
      textarea: '',
      maxHeight: null,
      trackData: {},
      tabList: data.tabList,
      active: -1,
      isToggle: false,
      tabIndex: 0,
    };
  },
  mounted() {
    //this.details();
    // this.getHeight()
    let self = this;
    self.getHeight();
    window.onresize = function () {
      self.getHeight();
    };
    this.getTrackList();
  },
  methods: {
    details() {
      websitePage({pageId: this.pageId}).then((res) => {
        this.pageContent = res.data;
      });
    },
    handleClick(tab, event) {
      this.tabIndex = tab.index;
    },
    getHeight() {
      let height =
          document.documentElement.clientHeight || document.body.clientHeight;
      this.maxHeight = height - 50 - 69;
      console.log(this.maxHeight);
    },
    getTrackList() {
      if (this.textarea) {
        var trackNumbers = []
        let splits = this.textarea.split(/[\s\n]/);
        if (splits) {
          splits.forEach((item) => {
            if (item && item.trim().length>0) {
              trackNumbers.push(item.trim())
            }
          })
        }

        if (trackNumbers.length > 20) {
          this.$message.warning('最多只能输入20个跟踪号')
          return
        }

        var temp = ''
        trackNumbers.forEach((item) => {
          temp = temp + item + '\n'
        })

        this.textarea =  temp

        expressTrack({trackNumbers: trackNumbers}).then((res) => {
          this.trackData = res.data;
          this.resetData();
        });
      }
    },
    setValueLength() {
      if (this.textarea) {
        let trackNumbers = this.textarea.trim().split(/[\s\n]/);
        if (trackNumbers.length > 20) {
          this.$message.error('最多只能输入20个跟踪号')
        }
      }
    },
    resetTrack(data) {
      data.tracks.map((item) => {
        item.check = false;
      });
      return data;
    },
    toggle(index, j, item) {
      console.log(j)
      console.log(index)
      if (this.tabList[j].data[index].check) {
        this.$set(this.tabList[j].data[index], "check", false);
      } else {
        this.$set(this.tabList[j].data[index], "check", true);
      }
      this.$forceUpdate()
      console.log(this.tabList[j].data[index])
    },
    resetData() {
      this.tabList.map((item) => {
        item.data = []
        item.count = this.trackData[item.status];
        this.trackData.tracks.forEach((i) => {
          i.check = false;

          if (item.name == -1) {
            item.data.push(i);
          }
          if (item.name == i.status) {
            i.icon = item.icon1
            i.bg = item.bg
            item.data.push(i);
          }
        });
      });
      console.log(this.tabList);
    },
  },
  computed: {},
  watch: {
    pageId(val) {
      if (val) {
        console.log(val);
        this.details();
      }
    },
    /*textarea(val) {
      if (val) {
        var temp = ''
        let trackNumbers = val.split(/[\s\n]/);
        if (trackNumbers) {
          var i = 0
          trackNumbers.forEach((item) => {
            if (item && item.trim()) {
              if (i < 20) {
                temp = temp + item.trim() + '\n'
              } else {
                this.$message.warning('最多只能输入20个跟踪号')
              }
              i++
            }
          })
        }

        this.textarea = temp
      }
    }*/
  },
};
</script>


<style lang="less" scoped>
.btn {
  margin-top: 30px;

  /deep/ .el-button {
    width: 100%;
  }
}

/deep/ .el-timeline {
  padding-left: 10px;
  text-align: left;
}

.aside-Guide {
  padding: 15px;

  .aside-menu-title {
    padding-left: 10px;
  }

  li {
    p {
      font-size: 16px;
      text-align: left;
      font-weight: 500;
      color: #333;
    }
  }

  .font-18 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 15px;
  }
}

.page-main-title {
  text-align: left;
  font-size: 30px;
  font-weight: 400;
  color: #24549d;
  font-size: 30px;
  margin-top: 30px;
}

.icon-i {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.ps-color-00 {
  color: #757575 !important;
  font-style: normal;
}

.ps-color-00-bg {
  background: #757575;
}

.ps-color-11 {
  color: #2196f3 !important;
  font-style: normal;
}

.ps-color-11-bg {
  background: #2196f3 !important
}

.ps-color-22 {
  color: #0d47a1;
  font-style: normal;
}

.ps-color-22-bg {
  background: #0d47a1 !important
}

.ps-color-33 {
  color: #f44336;
  font-style: normal;
}

.ps-color-33-bg {
  background: #f44336 !important;
}

.ps-color-44 {
  color: #43a047;
  font-style: normal;
}

.ps-color-44-bg {
  background: #43a047 !important;

}

.ps-color-55 {
  color: #ff6f00;
  font-style: normal;
}

.ps-color-55-bg {
  background: #ff6f00 !important;
}

.ps-color-66 {
  color: #b71c1c;
  font-style: normal;
}

.ps-color-66-bg {
  background: #b71c1c !important;
}

.icon-all {
  font-style: normal;
  color: #757575 !important;
}

.icon-djs {
  height: 22px;
  background: url("../assets/img/djs.png") no-repeat;
  background-size: cover;
}

.icon-djs-1 {
  height: 22px;
  background: url("../assets/img/djs-1.png") no-repeat;
  background-size: cover;
}

.icon-fj {
  background: url("../assets/img/fj.png") no-repeat;
  background-size: cover;
}

.icon-fj-1 {
  background: url("../assets/img/fj-1.png") no-repeat;
  background-size: cover;
}

.icon-qizi {
  height: 22px;
  background: url("../assets/img/qizi.png") no-repeat;
  background-size: cover;
}

.icon-qizi-1 {
  height: 22px;
  background: url("../assets/img/qizi-1.png") no-repeat;
  background-size: cover;
}

.icon-bs {
  background: url("../assets/img/bs.png") no-repeat;
  background-size: cover;
}

.icon-bs-1 {
  background: url("../assets/img/bs-1.png") no-repeat;
  background-size: cover;
}

.icon-dh {
  background: url("../assets/img/dh.png") no-repeat;
  background-size: cover;
}

.icon-dh-1 {
  background: url("../assets/img/dh-1.png") no-repeat;
  background-size: cover;
}

.icon-js {
  background: url("../assets/img/js.png") no-repeat;
  background-size: cover;
}

.icon-js-1 {
  background: url("../assets/img/js-1.png") no-repeat;
  background-size: cover;
}

.icon-cz {
  background: url("../assets/img/cz.png") no-repeat;
  background-size: cover;
}

.icon-cz-1 {
  background: url("../assets/img/cz-1.png") no-repeat;
  background-size: cover;
}

.custom-tab {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.tabs-item-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .success-icon {
    display: inline-block;
    width: 40px;
    height: 40px;
    background: #43a047;
    border-radius: 2px;
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    i {
      display: inline-block;
      width: 20px;
      height: 20px;
    }
  }

  .col-1 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .wldh {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #212121;

    .dh {
      display: flex;
      flex-direction: row;

      span {
        font-size: 15px;
        font-weight: 500;
      }

      .fz {
        display: inline-block;
        width: 20px;
        height: 20px;
        background: url("../assets/img/fz.png") no-repeat;
        background-size: cover;
      }
    }

    .day {
      font-size: 13px;
      text-align: left;
    }
  }

  .address {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;

    i {
      display: inline-block;
      width: 32px;
      height: 32px;
      margin-left: 5px;
      margin-right: 5px;
      background: url('../assets/img/jt.png') no-repeat;
      background-size: cover;
      transform: rotateX(180deg);
    }
  }

  .details {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    font-size: 15px;
    font-weight: 500;
  }
}

.custom-popover {
  padding: 10px;

  h6 {
    padding-bottom: 10px;
  }

  p {
    color: #000;
    padding-bottom: 10px;
  }

  .content {
    p {
      color: #757575;
      padding-bottom: 10px;
    }
  }
}

.i-block {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-right: 15px;

  i {
    font-size: 12px;
  }
}

.el-timeline-p {
  padding-left: 10px;
  text-align: left;
  padding-bottom: 20px;
}

/deep/ .el-tabs--border-card > .el-tabs__content {
  padding-right: 0;
  padding-bottom: 0;
}
</style>